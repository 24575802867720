<template>
	<app background="#fff">
		<claim-apply ref="claimApply"></claim-apply>
		
		<button-list-m slot="foot">
			<c-button @click="submit">确认并提交</c-button>
		</button-list-m>
	</app>
</template>

<script>
	import claimApply from './claim_apply.vue'
	
	export default {
		components: {
			claimApply
		},
		
		props: {
			id: String
		},
		
		mounted() {
			this.$refs.claimApply.init(this.id);
		},
		
		methods: {
			submit() {
				this.$refs.claimApply.submit().then(() => {
					this.$message({
						text: '提交成功',
						resolve: () => {
							this.navigateTo('/insurance_list');
						}
					});
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>